<template>
    <div id="community-guidelines">
      <b-container class="base-container-x member-benefit-header-box" style="min-height:500px;background-color:#fff">
        <div v-if="loading" class="col-12">
          <b-skeleton-img no-aspect height="200px"></b-skeleton-img>
        </div>
  
        <div v-if="!loading">
          <div id="banner" class="banner-inner community-guidelines">
            <div class="slideshow_caption show">
              <div class="slideshow_inner_caption">
                <div class="slideshow_align_caption">
                  <h1 class="section-header-title text-uppercase">{{ $t('Community Guidelines for Members') }}</h1>
                </div>
              </div>
            </div>
          </div>
  
          <div class="col-12 mt-4">
            <b-row class="guideline-row" v-for="(section, index) in sections" :key="index">
              <b-col cols="12" md="4">
                <hr class="bold" />
                <h4>{{ section.title }}</h4>
              </b-col>
              <b-col cols="12" md="8">
                <div v-for="(item, idx) in section.items" :key="idx" class="mb-1">
                  <hr class="normal" />
                  <div class="row questions-block" :id="`toggle-guideline-${index}-${idx}`" v-on:click="toggleCollapse(index, idx)" style="cursor: pointer;">
                    <div class="col-10">
                      <h5>{{ item.title }}</h5>
                    </div>
                    <div class="col-2 text-right">
                      <font-awesome-icon :icon="isOpen(index, idx) ? ['fas', 'minus'] : ['fas', 'plus']" />
                    </div>
                  </div>
                  <b-collapse :id="`guideline-${index}-${idx}`" v-model="collapses[`guideline-${index}-${idx}`]" accordion="guideline-accordion" role="tabpanel">
                    <b-card-body>
                      <div v-html="item.content"></div>
                    </b-card-body>
                  </b-collapse>
                </div>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-container>
    </div>
  </template>
  
  <script>
  export default {
    name: 'CommunityGuidelines',
    title: ' | Community Guidelines for Members',
    data () {
      return {
        loading: true,
        collapses: {
          'guideline-0-0': true
        },
        sections: [
          {
            title: this.$t('1. Introduction'),
            items: [
              {
                title: this.$t('WYA Mission and Community Commitment'),
                content: `
                  <p>${this.$t('The World Yoga Alliance (WYA) is committed to fostering a respectful, inclusive, and professional community for all members. These guidelines ensure a safe, ethical, and supportive space where all members, including yoga schools, teachers, and students, can connect, learn, and grow in alignment with the principles of traditional Indian yoga standards.')}</p>
                  <p><strong>${this.$t('** Please be reminded that adherence to all WYA policies, codes of conduct, and guidelines is mandatory for all members. Additionally, members are required to report any violations or concerns to WYA administrators promptly.')}</strong></p>
                  <p>${this.$t('By becoming a member of WYA, you agree to uphold these community guidelines. Failure to comply may result in suspension or removal from the community.')}</p>
                `
              }
            ]
          },
          {
            title: this.$t('2. Code of Conduct'),
            items: [
              {
                title: this.$t('2.1 Respect for All Members'),
                content: `
                  <ul>
                    <li>${this.$t('A. Treat all members with kindness, respect, and professionalism.')}</li>
                    <li>${this.$t('B. Discriminatory, offensive, or abusive behavior based on race, gender, religion, nationality, or any other identity will not be tolerated.')}</li>
                    <li>${this.$t('C. Engage in constructive discussions and avoid personal attacks or negativity.')}</li>
                    <li>${this.$t('D. Contribute positively to the community by sharing insights, experiences, and resources.')}</li>
                    <li>${this.$t('E. Participate in events, discussions, and initiatives that align with WYA’s mission.')}</li>
                  </ul>
                `
              },
              {
                title: this.$t('2.2 Ethical Behavior and Accountability'),
                content: `
                  <ul>
                    <li>${this.$t('A. Uphold the values of honesty, integrity, and fairness in all interactions.')}</li>
                    <li>${this.$t('B. Do not engage in fraudulent activities, misrepresentation, or unethical business practices.')}</li>
                    <li>${this.$t('C. Maintain professional boundaries in teacher-student and peer relationships.')}</li>
                    <li>${this.$t('D. Strive for personal and collective growth through the practice and teachings of yoga.')}</li>
                    <li>${this.$t('E. Take responsibility for your actions and their impact on the community.')}</li>
                    <li>${this.$t('F. Be open to feedback and willing to make amends if necessary.')}</li>
                  </ul>
                `
              },
              {
                title: this.$t('2.3 Safe and Responsible Communication'),
                content: `
                  <ul>
                    <li>${this.$t('A. Refrain from sharing false, misleading, or harmful information about yoga or its practices.')}</li>
                    <li>${this.$t('B. Avoid excessive self-promotion, spam, or unauthorized advertisements.')}</li>
                    <li>${this.$t('C. Respect privacy—do not share personal or confidential information without consent.')}</li>
                    <li>${this.$t('D. Be open to learning, sharing, and evolving within the community.')}</li>
                    <li>${this.$t('E. Support fellow members in their yoga journey.')}</li>
                  </ul>
                `
              }
            ]
          },
          {
            title: this.$t('3. Professional and Teaching Standards'),
            items: [
              {
                title: this.$t('3.1 Compliance with WYA Standards'),
                content: `
                  <ul>
                    <li>${this.$t('A. Members must follow WYA’s guidelines for yoga teaching, training, and certification.')}</li>
                    <li>${this.$t('B. Any school or teacher offering courses under WYA certification must meet the required teaching hours, curriculum, and ethical teaching practices.')}</li>
                  </ul>
                `
              },
              {
                title: this.$t('3.2 Student Welfare and Safety'),
                content: `
                  <ul>
                    <li>${this.$t('A. Provide a safe and supportive learning environment for students.')}</li>
                    <li>${this.$t('B. Ensure that physical adjustments and instructions are given with consent and in a respectful manner.')}</li>
                    <li>${this.$t('C. Promote inclusive teaching that respects cultural diversity and different abilities.')}</li>
                    <li>${this.$t('D. Prioritize the health and safety of yourself and others in all yoga practices.')}</li>
                    <li>${this.$t('E. Encourage safe and mindful practices, especially when teaching or guiding others.')}</li>
                  </ul>
                `
              },
              {
                title: this.$t('3.3 Authenticity in Yoga Practices'),
                content: `
                  <ul>
                    <li>${this.$t('A. Teach yoga in an authentic manner, respecting its roots and philosophy.')}</li>
                    <li>${this.$t('B. Avoid commercialization or dilution of traditional yoga principles for personal gain.')}</li>
                    <li>${this.$t('C. Do not claim false lineage, experience, or expertise beyond actual qualifications.')}</li>
                  </ul>
                `
              }
            ]
          },
          {
            title: this.$t('4. Online and On-site Community Participation'),
            items: [
              {
                title: this.$t('4.1 Online Behavior'),
                content: `
                  <ul>
                    <li>${this.$t('A. When engaging in online discussions, maintain a positive and respectful tone.')}</li>
                    <li>${this.$t('B. Do not post or share offensive, hateful, or inappropriate content.')}</li>
                    <li>${this.$t('C. Follow the guidelines of WYA’s website, forums, and social media platforms.')}</li>
                  </ul>
                `
              },
              {
                title: this.$t('4.2 Social Media Appearance and Guidelines'),
                content: `
                  <ul>
                    <li>${this.$t('A. WYA follows and abides by the community guidelines of all social media platforms to ensure a safe space for members.')}</li>
                    <li>${this.$t('B. No one can force WYA to contact their former students or pass-out graduates.')}</li>
                    <li>${this.$t('C. WYA Community is an open platform where anyone passionate about yoga can join and get promoted.')}</li>
                    <li>${this.$t('D. Any social media misconduct, including hate speech, harassment, or unethical promotions, will be subject to disciplinary action.')}</li>
                    <li>${this.$t('E. Members must represent themselves professionally and avoid misusing WYA\'s name for personal gain.')}</li>
                  </ul>
                `
              }
            ]
          },
          {
            title: this.$t('5. Reporting and Conflict Resolution'),
            items: [
              {
                title: this.$t('5.1 Reporting Violations'),
                content: `
                  <ul>
                    <li>${this.$t('A. If you witness or experience violations of these guidelines, report them to WYA through official channels.')}</li>
                    <li>${this.$t('B. Reports must be based on factual information and submitted with evidence if applicable.')}</li>
                    <li>${this.$t('C. WYA ensures confidentiality and fair investigation of reported issues.')}</li>
                  </ul>
                `
              },
              {
                title: this.$t('5.2 Conflict Resolution'),
                content: `
                  <ul>
                    <li>${this.$t('A. If conflicts arise, members are encouraged to resolve them respectfully and professionally.')}</li>
                    <li>${this.$t('Mediation may be provided by WYA for disputes between members.')}</li>
                    <li>${this.$t('Members must respect the final decisions made by WYA regarding disputes or violations.')}</li>
                  </ul>
                `
              }
            ]
          },
          {
            title: this.$t('6. Protection Against Scams, Spam, and Intellectual Property Theft'),
            items: [
              {
                title: this.$t('6.1 Prevention of Fraud and Scams'),
                content: `
                  <ul>
                    <li>${this.$t('A. Members must not engage in or support fraudulent activities, including false advertising of courses, fake certifications, or misleading claims about qualifications.')}</li>
                    <li>${this.$t('B. Any member found to be involved in scams, financial fraud, or unethical business practices will face immediate termination.')}</li>
                  </ul>
                `
              },
              {
                title: this.$t('6.2 No Spamming or Unsolicited Promotions'),
                content: `
                  <ul>
                    <li>${this.$t('A. Do not send unsolicited promotional messages, mass emails, or irrelevant advertisements to members.')}</li>
                    <li>${this.$t('B. Any marketing or promotion must be approved by WYA and aligned with ethical yoga practices.')}</li>
                  </ul>
                `
              },
              {
                title: this.$t('6.3 Protection of Intellectual Property'),
                content: `
                  <ul>
                    <li>${this.$t('A. Members must respect the intellectual property rights of WYA and fellow members.')}</li>
                    <li>${this.$t('B. Copying, reproducing, or distributing WYA materials, logos, training content, or certification documents without permission is strictly prohibited.')}</li>
                    <li>${this.$t('C. Any reported cases of plagiarism, content theft, or unauthorized course duplication will be investigated, and violators will be removed from the community.')}</li>
                    <li>${this.$t('D. Plagiarism or unauthorized use of WYA’s intellectual property will result in immediate action.')}</li>
                  </ul>
                `
              },
              {
                title: this.$t('6.4 Prohibition of Supporting or Creating Rival Yoga Alliance Bodies'),
                content: `
                  <ul>
                    <li>${this.$t('A. Members must not misuse WYA’s intellectual property, including its certification, training materials, or brand identity, to create or promote a competing yoga alliance.')}</li>
                    <li>${this.$t('B. Any misuse of WYA’s proprietary content for unauthorized business purposes will result in membership termination.')}</li>
                  </ul>
                `
              },
              {
                title: this.$t('6.5 Membership and License Revocation'),
                content: `
                  <ul>
                    <li>${this.$t('A. Members who wish to cancel their membership or license may do so by following the cancellation policy outlined on the World Yoga Alliance official website.')}</li>
                    <li>${this.$t('B. Refund policies, if applicable, are subject to the terms listed on the WYA legal agreement page.')}</li>
                  </ul>
                `
              },
              {
                title: this.$t('6.6 In case of WYA membership and license revocation'),
                content: `
                  <ul>
                    <li>${this.$t('A. If a member\'s WYA membership is revoked or blocked, they cannot compel World Yoga Alliance to remove their graduates or other associated members from the WYA Community system. However, members who wish to cancel their membership or license may do so by following the cancellation policy outlined in the legal agreement page on the World Yoga Alliance official website.')}</li>
                    <li>${this.$t('B. Graduates and other members associated with the revoked member or school may continue to be part of WYA, provided they adhere to WYA’s guidelines and policies.')}</li>
                    <li>${this.$t('C. WYA reserves the right to retain all member data and records in accordance with its policies and legal obligations.')}</li>
                    <li>${this.$t('D. WYA will not tolerate any attempts to pressure or manipulate its decisions.')}</li>
                    <li>${this.$t('E. WYA reserves the right to take legal action in cases of fraud, defamation, or targeted harassment against the revoked member.')}</li>
                  </ul>
                `
              },
              {
                title: this.$t('6.7 Zero Tolerance for Defamation and Interference'),
                content: `
                  <ul>
                    <li>${this.$t('A. WYA does not tolerate false accusations, defamation, or malicious interference with its operations. If any member makes defamatory statements or spreads misleading information with the intent to harm WYA’s reputation, WYA reserves the right to take legal action.')}</li>
                    <li>${this.$t('B. However, WYA respects legitimate legal complaints and will address grievances through appropriate legal or arbitration channels.')}</li>
                  </ul>
                `
              }
            ]
          },
          {
            title: this.$t('7. Policy Updates and Member Responsibility'),
            items: [
              {
                title: this.$t('7. Policy Updates and Member Responsibility'),
                content: `
                  <ul>
                    <li>${this.$t('A. WYA will notify members of policy updates via their user profile on the official website. Additionally, WYA may send email or in-platform notifications to ensure transparency.')}</li>
                    <li>${this.$t('B. It is the member’s responsibility to stay updated on policy changes and comply with the latest guidelines.')}</li>
                  </ul>
                `
              }
            ]
          },
          {
            title: this.$t('8. Consequences of Violating the Guidelines'),
            items: [
              {
                title: this.$t('8. Consequences of Violating the Guidelines'),
                content: `
                  <p>${this.$t('Failure to adhere to these guidelines may result in:')}</p>
                  <ol>
                    <li>${this.$t('Warning – A formal notice to correct behavior.')}</li>
                    <li>${this.$t('Temporary Suspension – Restriction of membership privileges for a specified time.')}</li>
                    <li>${this.$t('Membership Termination – Permanent removal from WYA with no refund.')}</li>
                  </ul>
                  <p>${this.$t('Decisions regarding violations will be made at WYA’s discretion to protect the integrity of the community.')}</p>
                `
              }
            ]
          },
          {
            title: this.$t('9. Dispute Resolution Process'),
            items: [
              {
                title: this.$t('9. Dispute Resolution Process'),
                content: `
                  <p>${this.$t('WYA is committed to resolving disputes in a fair, transparent, and professional manner. All members agree to follow the steps outlined below before taking any legal action.')}</p>
                  <h5>${this.$t('9.1 Mediation & Internal Resolution')}</h5>
                  <ul>
                    <li>${this.$t('A. If a dispute arises between a member and WYA, the member must first attempt to resolve the issue by submitting a formal complaint through WYA’s official dispute resolution channel.')}</li>
                    <li>${this.$t('B. WYA will review the complaint and attempt to mediate a resolution within 30 days of receiving the complaint.')}</li>
                    <li>${this.$t('C. Both parties must engage in good faith discussions and provide any relevant supporting evidence for their claims.')}</li>
                  </ul>
                  <h5>${this.$t('9.2 Arbitration Clause')}</h5>
                  <ul>
                    <li>${this.$t('A. If mediation fails, the dispute may be referred to arbitration, as per the Arbitration and Conciliation Act, 1996 (India).')}</li>
                    <li>${this.$t('B. The arbitration will be conducted by a neutral third-party arbitrator agreed upon by both WYA and the member.')}</li>
                    <li>${this.$t('C. The arbitration proceedings will take place in New Delhi, India, and will be conducted in English.')}</li>
                    <li>${this.$t('D. The decision of the arbitrator will be final and binding on both parties.')}</li>
                  </ul>
                  <h5>${this.$t('9.3 Legal Jurisdiction')}</h5>
                  <ul>
                    <li>${this.$t('A. In cases where arbitration is not applicable or fails, the courts of New Delhi, India will have exclusive jurisdiction over any legal disputes between members and WYA.')}</li>
                    <li>${this.$t('B. Members waive the right to file lawsuits in any other jurisdiction outside India.')}</li>
                  </ul>
                  <h5>${this.$t('9.4 Prohibition of Malicious Complaints')}</h5>
                  <ul>
                    <li>${this.$t('A. Members must not file false complaints or use the dispute resolution process to harass WYA.')}</li>
                    <li>${this.$t('B. If a complaint is found to be baseless or made with malicious intent, WYA reserves the right to take appropriate legal action.')}</li>
                  </ul>
                `
              }
            ]
          }
        ]
      }
    },
    async mounted () {
      window.scrollTo(0, 0)
      await this.toggleBurgerButtonClass('addClass', 'bars-bg-gray')
      this.loading = false

    document.addEventListener('contextmenu', function (e) {
      e.preventDefault();
    });

    document.addEventListener('copy', function (e) {
      e.preventDefault();
    });
    },
    methods: {
      toggleBurgerButtonClass (mode, className) {
        const burger = document.querySelector('.bm-burger-button')
        if (burger) {
          mode === 'addClass' ? burger.classList.add(className) : burger.classList.remove(className)
        }
      },
      toggleCollapse (sectionIndex, itemIndex) {
        const key = `guideline-${sectionIndex}-${itemIndex}`
        this.$set(this.collapses, key, !this.collapses[key])
      },
      isOpen (sectionIndex, itemIndex) {
        return this.collapses[`guideline-${sectionIndex}-${itemIndex}`]
      }
    },
    destroyed () {
      this.toggleBurgerButtonClass('removeClass', 'bars-bg-gray');

      document.removeEventListener('contextmenu', function (e) {
        e.preventDefault();
      });

      document.removeEventListener('copy', function (e) {
        e.preventDefault();
      });
    }
  }
  </script>
  
  <style>
  #community-guidelines {
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
  }
  hr.normal { border: 1px solid black; }
  hr.bold { border: 2px solid black; }
  .questions-block {
    cursor: pointer;
  }
  .questions-block:hover {
    color: red;
  }
  .questions-block:focus {
    outline: none !important;
  }
  #community-guidelines ul {
    list-style-type: none;
    padding-left: 0;
  }
  </style>